import { useSignalEffect } from "@preact/signals-react";
import { log } from "./logger";
import { client } from "./nats-client";
 
const tag = "finapp";
let loaded = false;
export function useFinApp(onSearch) {
  useSignalEffect(() => {
    if (loaded) return;
 
    (async () => {
      try {
        const m = await import("https://fwc-ui.markitqa.com/fwc-load-app-fwc-all-apps.js");
        let token: string;
        const finctx = await m.registerFinApps({
          tokenProvider: async (renewToken) => {
            // console.log("fin token", renewToken);
            if (renewToken || token === undefined) {
              token = await requestToken();
            }

            return token;
          },
          renewToken: async (renewToken) => {
            // console.log("renew fin token", renewToken);
            token = await requestToken();
            return token;
          },
        });
        log.info(tag, "Fin App Context", finctx);
 
        finctx.addEventListener("search-selection", (e) => {
          const data = e?.detail?.data;
          if (!data) return;
          if (onSearch) onSearch(data);
        });
        loaded = true;
      } catch (ex) {
        log.error(tag, "Failed to load fin apps", ex);
      }
    })();
  });
}
async function requestToken() {
  const finAppTokenCreate = await client.request<{ Token: string }>("finapptoken.create", {
    Issuer: new URL(window.location.href).hostname
  });

  if (!finAppTokenCreate.success || finAppTokenCreate.data === undefined) {
    log.error(tag, "finapp create token failed", { finAppTokenCreate });

    return "";
  }

  return finAppTokenCreate.data.Token;
}